import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveComment,
  getCommentSignalRHasUpdate,
  getIsActionLoadingComent,
  getIsLoadingComments,
  getIsLoadingShareLinkComment,
  getShareLinkComment,
} from "Store/Activities/Activities.selector";
import { VirtuosoHandle } from "react-virtuoso";
import { ActivitiesActions } from "Store/Activities/Activities.actions";
import { getUserInfo } from "Store/Auth/Auth.selector";
import { IInputData } from "./components/Input";
import { copyToClipboard } from "Utils/TextUtils";
import { history } from "Store";
import {
  IComment,
  ICreateCommentRequest,
  IEditCommentRequest,
} from "Data/interfaces/Activities/IComments";
import toastHandler from "Utils/toastHandler";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import { ITopicDnDResponse } from "Data/interfaces/Activities/IDragAndDropDataFormat";

interface IUseComments {
  updateActivity: ITopicDnDResponse | null;
  workComment: string | null;
}

const useComments = ({ updateActivity, workComment }: IUseComments) => {
  const dispatch = useDispatch();

  const activeComment = useSelector(getActiveComment);
  const isLoadingComments = useSelector(getIsLoadingComments);
  const isActionLoadingComent = useSelector(getIsActionLoadingComent);
  const commentSignalRHasUpdate = useSelector(getCommentSignalRHasUpdate);
  const shareLinkComment = useSelector(getShareLinkComment);
  const isLoadingShareLinkComment = useSelector(getIsLoadingShareLinkComment);
  const userInfo = useSelector(getUserInfo);
  const theme = useSelector(getTheme);

  const virtuosoRef = useRef<VirtuosoHandle | null>(null);
  const scrollListenerRef = useRef(false);
  const dynamicCommentIdScroll = useRef<string | null>(null);
  const [copyingId, setCopyingId] = useState<string | null>(null);
  const [commentId, setCommentId] = useState<string | null>(workComment);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sentDelete, setSentDelete] = useState(false);
  const [editComment, setEditComment] = useState<IComment | null>(null);
  const [hasUpdate, setHasUpdate] = useState(false);
  const [visibleRange, setVisibleRange] = useState({
    startIndex: 0,
    endIndex: 0,
  });

  const handleSubmit = (data: IInputData) => {
    if (!updateActivity?.Guid || !userInfo?.Id) return;

    if (editComment) {
      const request: IEditCommentRequest = {
        topicId: updateActivity.Guid,
        commentText: data.text,
        commentId: editComment.Id,
      };
      dispatch(ActivitiesActions.editComment(request));
    } else {
      const request: ICreateCommentRequest = {
        topicId: updateActivity.Guid,
        commentText: data.text,
        creationAuthorFk: userInfo.Id,
      };
      dispatch(ActivitiesActions.createComment(request));

    }
  };

  const handleScrollToEnd = () => {
    setHasUpdate(false);
    virtuosoRef.current?.scrollToIndex({
      index: activeComment.comments.length - 1,
      behavior: 'smooth',
    });
    dispatch(ActivitiesActions.commentSignalRHasUpdate(null));
  };

  const handleCopyLink = (comment: IComment) => {
    if (!updateActivity?.Guid) return;

    setCopyingId(comment.Id);
    const context = userInfo?.CurrentTenantContext;
    const url = `${window.location.origin}/construtora/${context}?redirectUrl=${encodeURIComponent(`${history.location.pathname}?workActivity=${updateActivity.Guid}&workComment=${comment.Id}`)}`;
    dispatch(ActivitiesActions.shareLinkComment({
      topicId: updateActivity?.Guid,
      commentId: comment.Id,
      originalURL: url,
    }));
  };

  const handleEdit = (comment: IComment) => {
    setEditComment(comment);
  };

  const handleDelete = (comment: IComment) => {
    setEditComment(comment);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    if (!updateActivity?.Guid || !editComment) return;

    dispatch(ActivitiesActions.deleteComment({
      topicId: updateActivity.Guid,
      commentId: editComment.Id,
    }));
    setSentDelete(true);
  };

  const handleClose = () => {
    setSentDelete(false);
    setShowDeleteModal(false);
    setEditComment(null);
  };

  useEffect(() => {
    if (
      activeComment.comments &&
      activeComment.comments.length > 0 &&
      !commentSignalRHasUpdate
    ) {
      if (workComment) {
        const commentIndex = activeComment.comments.findIndex((comment) => {
          return comment.Id === workComment;
        });
        if (commentIndex !== -1) {
          setTimeout(() => {
            virtuosoRef.current?.scrollToIndex({
              index: commentIndex,
              behavior: 'smooth',
            });
          }, 500);
          setTimeout(() => {
            scrollListenerRef.current = true;
          }, 1500);
          return;
        }
      }
      virtuosoRef.current?.scrollToIndex({
        index: activeComment.comments.length - 1,
        behavior: 'smooth',
      });
      setTimeout(() => {
        scrollListenerRef.current = true;
      }, 1000);
      return;
    }
  }, [activeComment.comments, workComment, commentSignalRHasUpdate]);

  useEffect(() => {
    if (!!shareLinkComment) {
      copyToClipboard(shareLinkComment);
      toastHandler.showSuccess('Link copiado com sucesso!');
      dispatch(ActivitiesActions.shareLinkCommentClear());
      setCopyingId(null);
    }
  }, [shareLinkComment, dispatch]);

  useEffect(() => {
    if (sentDelete && !isActionLoadingComent) {
      handleClose();
    }
  }, [sentDelete, isActionLoadingComent]);

  useEffect(() => {
    if (
      commentSignalRHasUpdate &&
      commentSignalRHasUpdate?.TopicId === updateActivity?.Guid &&
      activeComment.comments &&
      activeComment.comments.length > 0 &&
      dynamicCommentIdScroll.current !== commentSignalRHasUpdate?.Id &&
      activeComment.comments.some((comment) => {
        return comment.Id === commentSignalRHasUpdate?.Id;
      })
    ) {
      dynamicCommentIdScroll.current = commentSignalRHasUpdate?.Id;
      if (visibleRange.endIndex + 1 === activeComment.comments.length - 1) {
        virtuosoRef.current?.scrollToIndex({
          index: activeComment.comments.length - 1,
          behavior: 'smooth',
        });
        dispatch(ActivitiesActions.commentSignalRHasUpdate(null));
      } else {
        setHasUpdate(true);
      }
    }
  }, [
    commentSignalRHasUpdate,
    updateActivity?.Guid,
    activeComment.comments,
    visibleRange,
    dispatch,
  ]);

  return {
    activeComment,
    isLoadingComments,
    isActionLoadingComent,
    isLoadingShareLinkComment,
    userInfo,
    theme,
    virtuosoRef,
    scrollListenerRef,
    copyingId,
    commentId,
    showDeleteModal,
    editComment,
    hasUpdate,
    handleSubmit,
    handleCopyLink,
    handleEdit,
    handleDelete,
    confirmDelete,
    handleClose,
    setCommentId,
    handleScrollToEnd,
    setVisibleRange,
  };
};

export default useComments;

import { FC } from "react";
import { GetAvatar } from "Utils/generateThumbnail";
import { formatDateFromNow } from "Utils/DateUtils";
import { IComment } from "Data/interfaces/Activities/IComments";
import ActionButtons from "../ActionButtons";
import { Icon } from "Components/UI";
import SpinCustom from "Components/UI/SpinCustom";
import { IMDTheme } from "Data/interfaces/MultiDomain/IMDTheme";
import { IUserInforResponse } from "Data/interfaces/Auth/IUserInfoResponse";
import AccessPermission from "Utils/AcessPermission";
import {
  Wrapper,
  Container,
  Header,
  Content,
  OwnerBadge,
  ActionButton,
} from "./styles";

interface ICard {
  item: IComment;
  first?: boolean;
  isOwner?: boolean;
  loadingShareLink?: boolean;
  active?: boolean;
  theme?: IMDTheme;
  userInfo?: IUserInforResponse;
  onCopyLink: (comment: IComment) => void;
  onEdit: (comment: IComment) => void;
  onDelete: (comment: IComment) => void;
}

const Card: FC<ICard> = ({
  item,
  first,
  isOwner,
  loadingShareLink,
  active,
  theme,
  userInfo,
  onCopyLink,
  onEdit,
  onDelete,
}) => {

  return (
    <Wrapper first={first}>
      <Container active={active}>
        <Header>
          <span className="headerText">
            <span className="headerLeftText">
              <GetAvatar
                src={item.CreationAuthor.OriginalImageUrl}
                thumbSrc={item.CreationAuthor.ThumbnailImageUrl}
                name={item.CreationAuthor?.Nome || ''}
                size={24}
                fontSize={12}
              />
              <span className="name">{item.CreationAuthor.Nome || ''}</span>
            </span>
            {isOwner && <OwnerBadge>Responsável</OwnerBadge>}
          </span>
          <span className="date">
            {formatDateFromNow(item.DateTimeUtcFormated)}
          </span>
          <span className="actions">
            <ActionButton onClick={() => onCopyLink(item)}>
              {loadingShareLink && (
                <SpinCustom
                  size={10}
                  color={theme?.colors.surface.onSurfaceVariant}
                />
              )}
              {!loadingShareLink && (
                <Icon
                  icon="copiar"
                  customSize={16}
                  className="iconCopyLink"
                />
              )}
            </ActionButton>
            <ActionButtons
              loadingShareLink={loadingShareLink}
              hasDeletePermission={userInfo?.Id === item.CreationAuthorFk ||
                AccessPermission.isAdmin()
              }
              onCopyLink={() => onCopyLink(item)}
              onEdit={() => onEdit(item)}
              onDelete={() => onDelete(item)}
            />
          </span>
        </Header>
        <Content>
          <span className="description"><pre>{item.CommentText}</pre></span>
        </Content>
      </Container>
    </Wrapper>
  )
}

export default Card;
